var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("\n    Matches\n    "),_c('v-spacer'),_vm._v(" "),_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1 matches",attrs:{"headers":_vm.computedHeaders,"items":_vm.matches,"options":_vm.options,"server-items-length":_vm.totalMatches,"loading":_vm.loading,"footer-props":_vm.footerProps,"show-expand":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"0px 16px"},attrs:{"colspan":"8"}},[_c('v-container',{attrs:{"fill-height":"","fluid":"","align":'start'}},[_c('v-row',{staticClass:"lighten-5",attrs:{"align":'start',"justify":'center'}},[_c('v-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('character-summary',{attrs:{"character-id":item.character_a.id}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('character-summary',{attrs:{"character-id":item.character_b.id}})],1)],1)],1)],1)]}},{key:"item.character_a",fn:function(ref){
var item = ref.item;
return [_c('character-slot',{attrs:{"character":item.character_a,"winner-id":item.winner.id}})]}},{key:"item.character_b",fn:function(ref){
var item = ref.item;
return [_c('character-slot',{attrs:{"character":item.character_b,"winner-id":item.winner.id}})]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm.formatDate(item.date))+"\n    ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }